import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import app_icon_faworiter_lines from '../../assets/png/app_icon_faworiter_lines.png';
import app_icon_faworiter_story from '../../assets/png/app_icon_faworiter_story.png';
import headerImg1 from '../../assets/png/headerImg1.jpg';
import faworiterBw from '../../assets/png/faworiter_bw.png';
import { ReactComponent as Gdpr } from '../../assets/svg/gdpr.svg';
import { ReactComponent as Rate } from '../../assets/svg/rate.svg';
import { ReactComponent as LogoFaworiterWhite } from '../../assets/svg/logo_faworiter_white.svg';
import backgroundImgage from '../../assets/png/mystoryimg.png';
import LanguageSwitcher from '../../components/languageSwitcher/LanguageSwitcher';
import AppleStore from '../../components/appleStore/AppleStore';
import { Parallax } from 'react-parallax';
import './MainPage.css';

const MainPage = ({changeLocale}) => {

    const intl = useIntl();

    return (
        <div className={'main-page'}>
            {/* <div className={'main-page-header'}>
                <div className={'main-page-language-switcher'}><LanguageSwitcher changeLocale={changeLocale} /></div>
                <div className={'main-page-header-logo-block'}>
                    <LogoFaworiterWhite />
                    <span>– Lagom digitala skolappar</span>
                </div>
                <div className={'main-page-header-img-block'}>
                    <img className={'background-imgage'} src={backgroundImgage} alt='img' />
                </div>
            </div> */}
            <div className={'main-page-header'}>
                <Parallax 
                    bgImage={ backgroundImgage }
                    bgImageSize={100}
                    strength={200}
                    renderLayer={precentage => {
                        let precentage1 = (precentage/10)*100
                        return <div 
                            style={{
                                position: 'absolute',
                                width: '100%',
                                bottom: `${precentage1}%`,
                                marginBottom: '-13%',
                                paddingBottom: `${precentage1}%`,
                            }}
                        >
                            <div className={'main-page-header-logo-block'}>
                                <LogoFaworiterWhite />
                                <span>– Lagom digitala skolappar</span>
                            </div>
                        </div>
                    }}
                >
                </Parallax>
            </div>
            <div className={'main-page-body'}>
                <div className={'main-page-body-description'}>
                    <div className={'main-page-body-description-title'}>Vi ger dig förutsättningar att skapa analogt material med digitala verktyg</div>
                    <div className={'main-page-body-description-text'}>Faworiter älskar lärande och undervisning som ger alla elever förutsättningar att utvecklas. Därför har vi skapat en serie pedagogiska iOs-applikationer där fokus är i skaparglädje och kreativitet. I Faworiter möter digitalt lärande den analoga undervisningen. Med hjälp av Faworiters applikationer skapar du enkelt analogt pedagogiskt material till ditt klassrum.</div>
                </div>
                <AppBlock 
                    link={'/mystory'}
                    headerImg={headerImg1}
                    logo={app_icon_faworiter_story}
                    titles={'Faworiter Story'}
                    slogans={'För kreativt bokskapande'}
                    description={intl.formatMessage({id:'faworiter_story_description'})}
                    isDone={true}
                    linkToAppleStory={'https://apps.apple.com/se/app/faworiter-story/id1586432829'}
                />
                <AppBlock 
                    link={'/'}
                    headerImg={headerImg1}
                    logo={app_icon_faworiter_lines}
                    titles={'Faworiter Lines'}
                    slogans={'För en snyggare handstil'}
                    description={intl.formatMessage({id:'faworiter_lines_description'})}
                    isDone={true}
                    linkToAppleStory={'https://apps.apple.com/se/app/faworiter-lines/id1594354129'}
                />
            </div>
        </div>
    );
}

export default MainPage;

const AppBlock = ({link, headerImg, logo, titles, slogans, description, isDone, linkToAppleStory}) => {

    return (
        // <Link to={link}>
        <a href={linkToAppleStory} target='_blank'>
            <div className={`app-block ${isDone ? 'done' : ''}`}>
                {/* <div className={'app-block-header'}><img className={'header-img'} src={headerImg} alt='headerImg' /></div> */}
                <div className={'app-block-body'}>
                    <div className={'app-block-body-header'}>
                        <div className={'app-block-body-header'}>
                            <img className={'app-block-body-header-logo-img'} src={logo} alt='logo' />
                            <div className={'app-block-body-header-titles'}>
                                <span className={'app-block-body-header-titles-text'}>{titles}</span>
                                <span className={'app-block-body-header-titles-slogans'}><span>-</span>{slogans}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'app-block-description'}>
                        <div className={'app-block-description-icons'}>
                            <Gdpr className={'app-block-description-icons-img'} />
                            <Rate className={'app-block-description-icons-img'} />
                        </div>
                        <div className={'app-block-description-text'} dangerouslySetInnerHTML={{__html: description}}>
                        </div>
                    </div>
                    <div className={'apple-store-wrapper'}><AppleStore link={linkToAppleStory} /></div>
                    {/* <div className={`app-block-buttuns ${isDone ? 'read-more' : ''}`}>
                        {isDone ? 
                                <div><FormattedMessage id='read_more' /></div>
                            :
                                <div><FormattedMessage id='coming_soon' /></div>}
                    </div> */}
                </div>
            </div>
        </a>
        // </Link>
    )
}