import MainPage from './mainPage/MainPage';
import MyStory from './myStory/MyStory';
import CookiePolicy from './cookiePolicy/CookiePolicy';
import PrivacyAndTermsStory from './privacyAndTerms/PrivacyAndTermsStory';
import PrivacyAndTermsLines from './privacyAndTerms/PrivacyAndTermsLines';

export {
    MainPage,
    MyStory,
    CookiePolicy,
    PrivacyAndTermsStory,
    PrivacyAndTermsLines
}