import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getCookie, setCookie } from '../../utils/functions';
import './CookiAccept.css';

const CookiAccept = () => {

    const [isShowCookiAccept, setShowCookiAccept] = useState(getCookie('cookie-policy-banner'));

    const handelClickAccept = () => {
        const isCookis = 1
        setCookie('cookie-policy-banner', isCookis, 40000)
        setShowCookiAccept(isCookis)
    };

    return (
        <>
        {!isShowCookiAccept && 
            <div className={'cooki-accept'}>
                <div><FormattedMessage id='By using our website, you accept this' /> <Link to={'/cookie'}><FormattedMessage id='cookie_policy_lc' /></Link>.</div>
                <div onClick={handelClickAccept} className={'accept-and-continue'}><FormattedMessage id='accept_and_continue' /></div>
            </div>}
        </>
    );
}

export default CookiAccept;