import React from 'react';
import './StartTab.css';
import { FormattedMessage, useIntl } from 'react-intl';
import info_img1 from '../../../assets/png/info_img1.png';
import info_img2 from '../../../assets/png/info_img2.png';
import info_img3 from '../../../assets/png/info_img3.png';
import info_img4 from '../../../assets/png/info_img4.png';
import ipad_pic1 from '../../../assets/png/ipad_pic1.png';
import ipad_pic2 from '../../../assets/png/ipad_pic2.png';
import steps from '../../../assets/png/steps.png';
import account_circle from '../../../assets/svg/account_circle.svg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const StartTab = (props) => {

    const intl = useIntl();

    return (
        <div className={'start-tab'}>
            <div className={'start-tab-message'}>
                <img className={'account-img'} src={account_circle} alt='account' />
                <div>
                    <div className={'message-block'}><FormattedMessage id='message_block_text' /></div>
                    <img className={'message-block-img-steps'} src={steps} />
                </div>
            </div>
            <div className={'start-tab-description'}>
                <h2 className={'start-tab-description-header'}><FormattedMessage id='start_tab_description_header' /></h2>
                <div className={'start-tab-description-text'}><FormattedMessage id='start_tab_description_text' /></div>
            </div>
            <div className={'info-block'}>
                <div className={'info-block-header'}>
                    <span className={'info-block-header-num'}>1.</span>
                    <span className={'info-block-header-text'}><FormattedMessage id='start_tab_info_header_1' /></span>
                </div>
                <div className={'info-block-imgtext'}>
                    <div className={'info-block-bg-img'}>
                        <img className={'info-block-img'} src={info_img1} alt='img' />
                    </div>
                    <div className={'info-block-description'}><FormattedMessage id='start_tab_info_text_1' /></div>
                </div>
            </div>
            <div className={'info-block'}>
                <div className={'info-block-header'}>
                    <span className={'info-block-header-num'}>2.</span>
                    <span className={'info-block-header-text'}><FormattedMessage id='start_tab_info_header_2' /></span>
                </div>
                <div className={'info-block-imgtext'}>
                    <Carousel
                        autoPlay
                        infiniteLoop
                        interval={4000}
                        showArrows={false}
                        showStatus={false}
                    >
                        <img className={'info-block-img'} src={ipad_pic1} alt='img' />
                        <img className={'info-block-img'} src={ipad_pic2} alt='img' />
                    </Carousel>
                    {/* <img className={'info-block-img'} src={info_img2} alt='img' /> */}
                    <div style={{ marginLeft: '15px' }} className={'info-block-description'} dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'start_tab_info_text_2'})}}></div>
                </div>
            </div>
            <div className={'info-block'}>
                <div className={'info-block-header'}>
                    <span className={'info-block-header-num'}>3.</span>
                    <span className={'info-block-header-text'}><FormattedMessage id='start_tab_info_header_3' /></span>
                </div>
                <div className={'info-block-imgtext'}>
                    <div className={'info-block-bg-img'}>
                        <img className={'info-block-img'} src={info_img3} alt='img' />
                    </div>
                    <div className={'info-block-description'}><FormattedMessage id='start_tab_info_text_3' /></div>
                </div>
            </div>
            <div className={'info-block'}>
                <div className={'info-block-header'}>
                    <span className={'info-block-header-num'}>4.</span>
                    <span className={'info-block-header-text'}><FormattedMessage id='start_tab_info_header_4' /></span>
                </div>
                <div className={'info-block-imgtext'}>
                    <div className={'info-block-bg-img'}>
                        <img className={'info-block-img'} src={info_img4} alt='img' />
                    </div>
                    <div className={'info-block-description'}><FormattedMessage id='start_tab_info_text_4' /></div>
                </div>
            </div>
        </div>
    );
}

export default StartTab;