import React from 'react';
import {FormattedMessage} from 'react-intl';
import appleIcon from '../../assets/png/apple-icon.png';
import app_store_en from '../../assets/svg/app_store_en.svg';
import app_store_fi from '../../assets/svg/app_store_fi.svg';
import app_store_sw from '../../assets/svg/app_store_sw.svg';
import {getCookie} from '../../utils/functions';
import './AppleStore.css'

const AppleStore = ({link}) => {

    const locale = getCookie('locale') || 'en';

    return (
        <a href={link} target='_blank' className={'apple-block'} onClick={e => e.stopPropagation()}>
            {locale === 'en' && <img className={'app-store-icon'} src={app_store_en} alt='app store' />}
            {locale === 'fi' && <img className={'app-store-icon'} src={app_store_fi} alt='app store' />}
            {locale === 'sv' && <img className={'app-store-icon'} src={app_store_sw} alt='app store' />}
        </a>
    );
}

export default AppleStore;