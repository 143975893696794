import React from 'react';
import chevronDown from '../../assets/svg/chevronDown.svg';
import chevronUp from '../../assets/svg/chevronUp.svg';
import './Dropdown.css';

const { useState, useCallback} = React;

const Dropdown = React.memo(({chooseItemCallback, options, defaultItem}) => {

    const [isOpened, setOpened] = useState(false);
    const [chosenItem, setChosenItem] = useState(defaultItem);

    const toggleDropdown = useCallback(() => {
        setOpened(!isOpened);
    }, [isOpened]);


    const focusOut = useCallback(() => {
        setOpened(false);
    });

    const onclickItem = useCallback((item) => {
        setChosenItem(item);
        chooseItemCallback(item);
        setOpened(false);
    });

    const onKeyPress = useCallback((e) => {
        const key = e.key;

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            let currentOutlinedIndex = 0;

            // for cycle is more quick then array iteration methods
            for(let i = 0; i<options.length; i++) {
                if(options[i].key === chosenItem) {
                    currentOutlinedIndex = i;
                }
            }

            let newOutlinedItem;

            if (key === 'ArrowDown') {
                newOutlinedItem = currentOutlinedIndex === options.length - 1
                    ? options[0]
                    : options[currentOutlinedIndex + 1];
            }
            if (key === 'ArrowUp') {
                newOutlinedItem = currentOutlinedIndex === 0
                    ? options[options.length - 1]
                    : options[currentOutlinedIndex - 1];
            }

            setChosenItem(newOutlinedItem);
        }

        if (key === 'Enter') {
            chooseItemCallback(chosenItem);
        }
    });

    return (
        <div className={'drop-down'} onBlurCapture={focusOut} tabIndex={-1}>
            <div className={'label'}  onClick={toggleDropdown}>
                <div className={'label-value'}>{chosenItem && options.find(option => option.key === chosenItem).value}</div>
                {/* {isOpened ? <img src={chevronUp} className={'caret-icon'}/> : <img src={chevronDown} className={'caret-icon'}/>} */}
            </div>
            {isOpened && <div className={'list'}>
                {options.map(item => {
                    let className = 'list-item';
                    if (item.key === chosenItem) {
                        className += ' outlined';
                    }
                    return <div key={item.key}
                                className={className}
                                onClick={() => onclickItem(item.key)}
                                onKeyPress={onKeyPress}>{item.value}</div>;
                })}
            </div>}
        </div>
    );

});

export default Dropdown;