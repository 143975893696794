import React, { useEffect } from 'react';
import './CookiePolicy.css';

const CookiePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={'cookie-policy'}>
            <h2>Our Cookie policy</h2>
            <div>We use cookies to develop and improve the functionality of our website and ensure that the services we offer work as they should. By using our website, you agree to this cookie policy.</div>
            <div>A cookie is a small text file that our website requests to be saved on your device (for example, your computer, tablet or phone). We use both first-party cookies and third-party cookies (the latter are used for certain types of analysis). With the help of saved cookies, we can recognize your browser on subsequent visits to our website, see if you have logged in before and if you have created any settings.</div>
            <div>We use two basic types of cookie: a session cookie, which disappears automatically when you close your browser, and a persistent cookie which stores a text file on your device for a shorter or longer period of time after your visit (unless you delete it from your device – see below). We use persistent cookies, among other things, to keep you logged in to our website and to remember the various settings you have created. When you return to our website, new session cookies are placed in temporary memory and the persistent cookies are renewed. Below you will find more detailed information about the different cookies we use:</div>
            <ul>
                <li>Cookies to save information about how long your visit to our website lasted and information about what you have experienced on our website, e.g. which books you have read.</li>
                <li>Cookies to save your language settings.</li>
                <li>Cookies for Google Analytics, which we use to track and analyze reading over time.</li>
                <li>Cookies that remember that you have accepted this cookie policy.</li>
            </ul>
            <div>You can delete cookies from your browser and your device yourself. Information on how to do this can usually be found via the browser's help function. If you choose to disable cookies, this may mean that some functionalities on our website disappear and that your settings and logins are not saved. If you deactivate a cookie or a certain category of cookie, it does not mean that the cookie is removed from your browser - you must do it yourself in your browser.</div>
        </div>
    );
}

export default CookiePolicy;