import React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './MyStory.css';
import LanguageSwitcher from '../../components/languageSwitcher/LanguageSwitcher';
import logo from '../../assets/png/logo1.png';
import arrow_right from '../../assets/svg/arrow_right.svg';
import mystoryimg from '../../assets/png/mystoryimg.png';
import StartTab from './tabs/StartTab';
import HelpTab from './tabs/HelpTab';
import AppleStore from '../../components/appleStore/AppleStore';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const MyStory = ({changeLocale}) => {

    const [activTab, setActivTab] = useState('start');

    const history = useHistory();

    useEffect(() => {
        history.push('/mystory/start')
    }, [])
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const pushTo = (tabName, link) => {
        setActivTab(tabName)
        history.push(link)
    }

    return (
        <div className={'my-story'}>
            <div className={'my-story-header'}>
                <div className={'my-story-logo-app max-width'}>
                    <Link style={{ display: 'flex', alignItems: 'center' }} to={'/main'}>
                        <img style={{transform: 'rotate(180deg)', marginRight: '10px'}} src={arrow_right}/>
                        <div className={'logo-container'}>
                            <img className={'logo-img'} src={logo} alt='logo' />
                            <div className={'logo-container-text'}>
                                <span className={'logo-container-text-faworiter'}>FaWoRITER</span>
                                <span className={'logo-container-text-titles'}>My Story</span>
                            </div>
                        </div>
                    </Link>
                    <div className={'app-store-header'}><AppleStore /></div>
                </div>
            </div>
            <div className={'img-block'}>
                <img className={'background-imgage'} src={mystoryimg} alt='img' />
                <div className={'app-store-mobile'}><AppleStore /></div>
            </div>
            <div className={'my-story-body'}>
                <div className={'tabs'}>
                    <div className={`tab ${activTab === 'start' ? 'active' : ''}`} onClick={() => pushTo('start', '/mystory/start')}><FormattedMessage id='start' /></div>
                    <div className={`tab ${activTab === 'help' ? 'active' : ''}`} onClick={() => pushTo('help', '/mystory/help')}><FormattedMessage id='help' /></div>
                    <div className={'my-story-language-switcher'}><LanguageSwitcher changeLocale={changeLocale} /></div>
                </div>
                <Switch>
                    <Route exact path='/mystory/start' component={StartTab}/>
                    <Route exact path='/mystory/help' component={HelpTab}/>
                </Switch>
            </div>
        </div>
    );
}

export default MyStory;