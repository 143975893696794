import React from 'react';

const HelpTab = (props) => {
    return (
        <div>
            HelpTab
        </div>
    );
}

export default HelpTab;