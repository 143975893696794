import React, { useState } from 'react';
import {IntlProvider} from 'react-intl';
import {BrowserRouter} from 'react-router-dom';
import {getCookie, setCookie} from './utils/functions';
import App from './App';

const IntlContainer = React.memo(() => {

    const [locale, setLocale] = useState(getCookie('locale') || 'en')

    const resolvedLocale = locale;

    const changeLocale = (val) => {
        setLocale(val)
        setCookie('locale', val, 1000)
    }

    const messages = require(`./pages/localization/locales/${resolvedLocale.split('-')[0]}.json`);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <BrowserRouter>
                <App changeLocale={(val) => changeLocale(val)} />
            </BrowserRouter>
        </IntlProvider>
    );
});

export default IntlContainer;
