import React, { useEffect } from 'react';
import './PrivacyAndTerms.css';

const PrivacyAndTermsStory = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={'privacy-and-terms'}>
            <h1>Privacy Policy</h1>
            <div>Miber AB, org. No. 559039-3350, is concerned about your privacy. We do not collect, store or share any personal information or location data through our apps.</div>
            <h2>What information do Miber AB collect and how we might use them?</h2>
            <div>To be able to continuously improve the service our application we collect anonymous user tracking. These reports do not contain any personally identifiable info.</div>
            <h2>Cookies and other technologies</h2>
            <div>We use cookies to develop and improve the functionality of our website and ensure that the services we offer work as they should. By using our website, you agree to our cookie policy. Read more in our Cookie Policy.</div>
            <h2>Disclosure to third parties</h2>
            <div>We do not gather personally identifiable information (such as name, address, email or phone) in Faworiter Story. However, if you contact us, we will not share personally identifiable information with other companies.</div>
            <h2>Privacy issues</h2>
            <div>If you gave any questions or concerns about Miber AB:s Privacy Policy feel free to contact us. Send email to info@miber.se.</div>
            <div>This privacy policy was last updated on Dec 2021.</div>

            <h1 style={{ marginTop: '60px' }}>Terms of Use</h1>
            <h2>1. Information about us</h2>
            <div>These terms of use (“Terms of use”) including the Privacy Policy (“Privacy Policy”) and Cookie Policy (“Cookie Policy”) are established by Miber AB, org. No. 559039-3350, ("Miber" or "we / us / our").</div>
            <div>Miber AB is a company registered in Sweden. The Terms apply to you as an individual or legal entity (the "Customer" or "you / your") to the application Faworiter Story (“Faworiter Story/ the App”).</div>
            <h2>2. Information about these terms</h2>
            <div>Read these Terms of use and our Cookie Policy carefully before using Miber’s app Faworiter Story. By using the App, you confirm that you accept the terms.</div>
            <div>If you are under 18 years of age, you and your parent or guardian must review this agreement and the Privacy Policy together.</div>
            <div>Disputes arising from this agreement between Miber and the Customer shall be settled by a Swedish general court in application of Swedish law.</div>
            <div>If you download the App from Apple’s Store notice Apple Store’s rules, terms and policies for the App are included. In case of differences Apple’s rules, terms and policies apply.</div>
            <div>If you think that there is a mistake in these terms, please contact us by email info@miber.se to discuss.</div>
            <h2>3. Acceptable Usage Policy</h2>
            Users of Faworiter must:
            <ul>
                <li>not use the App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the Faworiter.</li>
                <li>not infringe our intellectual property rights or those of any third party in relation to your use of the App, including by the submission of any material (to the extent that such use is not licensed by these terms).</li>
                <li>not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Faworiter.</li>
                <li>not use the App in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users.</li>
                <li>not collect or harvest any information or data from any Faworiter or our systems or attempt to decipher any transmissions to or from the servers running any Service.</li>
            </ul>
            <h2>4. Intellectual property rights</h2>
            <div>All intellectual property except for user generated content contained in or on the App is owned by Miber or its licensors. All content in the App (except for user generated content) including, but not limited to, text, photos, videos, scripts, code, designs, graphics, software, sounds, music, interactive features and all other content is the proprietary property of Miber or its licensors. Miber reserves all of its rights in respect of the IPR contained in the App and in respect of the Content.</div>
            <div>You are responsible for your content. By accepting the terms, you agree not to create or publish material for which you do not have the appropriate copyright permission; for example, copyrighted text, pictures, symbols or videos. Without implying any obligation on our part, we reserve the right to remove user content as required by law or if we believe user content may create liability for us or violates these terms, or to modify user content as required by law or if necessary to cause user content to be in compliance with these Terms.</div>
            <h2>5. Loss or damage</h2>
            <div>Miber shall not be liable to you for any errors or omissions in the content of the App or in its availability. You confirm that you understand that no software or digital service is completely error-free and that, for example bugs may occur in the App.</div>
            <div>Miber is also not liable for any damages, whether direct or indirect, that are caused or arise as a result of the use of the App, including any damages that arise as a result of the Apps not being able to be used in the intended manner, e.g. when the App is temporarily down or disturbed.</div>
            <h2>6. Our rights to make changes</h2>
            <div>We amend these terms from time to time. These terms were updated on Dec 2021.</div>
            <div>We may also update or require you to update and change Faworiter Story from time to time to reflect changes to our users’ needs and our current business needs or because of changes to the law.</div>
        </div>
    );
}

export default PrivacyAndTermsStory;