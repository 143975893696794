import React from 'react'
import Dropdown from '../dropdown/Dropdown';
import lngIcon from '../../assets/svg/language.svg';

import {getCookie, setCookie} from '../../utils/functions';

import './LanguageSwitcher.css';

const languagesList = [
    {key: 'en', value: 'English', id: 2},
    {key: 'sv', value: 'Svenska', id: 1},
    {key: 'fi', value: 'Suomi', id: 3},
    // {key: 'uk', value: 'Українська', id: 4}
];

const LanguageSwitcher = React.memo(({changeLocale}) => {

    const locale = getCookie('locale') || 'en';

    const handleChange = (value) => {
        changeLocale(value)
        setCookie('locale', value, 10000)
    }
    
    return <div className={'lngs-witcher'}>
        <img src={lngIcon} className={'lng-icon'} />
        <Dropdown chooseItemCallback={handleChange}
                  options={languagesList}
                  defaultItem={locale}/>
    </div>
});

export default LanguageSwitcher;