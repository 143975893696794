import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
// import Header from './components/header/Header';
import { MainPage, MyStory, CookiePolicy, PrivacyAndTermsStory, PrivacyAndTermsLines } from './pages';
import Footer from './components/footer/Footer';
import CookiAccept from './components/cookiAccept/CookiAccept';

const App = React.memo(({changeLocale}) => {

  return (
    <div className='App'>
      {/* <Header changeLocale={changeLocale} /> */}
      <div className={'content'}>
        <Switch>
          <Redirect exact from='/' to={'/main'}/>
          <Route exact path='/main' component={() => <MainPage changeLocale={changeLocale} />}/>
          <Route path='/mystory' component={() => <MyStory changeLocale={changeLocale} />}/>
          <Route exact path='/cookie' component={CookiePolicy}/>
          <Route exact path='/terms-story' component={PrivacyAndTermsStory}/>
          <Route exact path='/terms-lines' component={PrivacyAndTermsLines}/>
        </Switch>
      </div>
      <Footer />
      <CookiAccept />
    </div>
  );
}) 

export default App;
