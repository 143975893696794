import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import iconFB from '../../assets/png/iconFB.png'
import iconINST from '../../assets/png/iconINST.png'
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className={'footer-content max-width'}>
                <div className={'footer-column'}>
                    <div className={'footer-column-title'}>Contact us</div>
                    <div className={'footer-column-block'}>
                        <span className={'footer-column-block-title'}>Address:</span>
                        <div>
                            <p>Miber AB</p>
                            <p>Saltövägen 2a</p>
                            <p>37137 Karlskrona</p>
                            <p>Sweden</p>
                        </div>
                    </div>
                    <div className={'footer-column-block'}>
                        <span className={'footer-column-block-title'}>E-mail:</span>
                        <a href="mailto:info@miber.se">info@miber.se</a>
                    </div>
                </div>
                <div className={'footer-column'}>
                    <div className={'footer-column-title'}>Legal documents</div>
                    <Link to={'/cookie'}><FormattedMessage id='cookie_policy' /></Link>
                    <Link to={'/terms-story'}><FormattedMessage id='privacy_and_terms' /> - Faworiter Story</Link>
                    <Link to={'/terms-lines'}><FormattedMessage id='privacy_and_terms' /> - Faworiter Lines</Link>
                </div>
                <div className={'footer-column'}>
                    <div className={'footer-column-title'}>Follow us</div>
                    <div className={'social-networks'}>
                        <a href='http://facebook.com/faworiter' target='_blank'>
                            <img className={'social-networks-img'} src={iconFB} alt='facbook' />
                        </a>
                        <a href='https://www.instagram.com/faworiter' target='_blank'>
                            <img className={'social-networks-img'} src={iconINST} alt='instagram' />
                        </a>
                    </div>
                </div>
                {/* <div className={'footer-column-cooki-social'}>
                    <div className={'cooki-social'}>
                        <div className={'footer-column'}>   
                            <Link to={'/cookie'}><FormattedMessage id='cookie_policy' /></Link>
                            <Link to={'/terms-story'}><FormattedMessage id='privacy_and_terms' /> - Faworiter Story</Link>
                            <Link to={'/terms-lines'}><FormattedMessage id='privacy_and_terms' /> - Faworiter Lines</Link>
                        </div>
                        <div className={'social-networks'}>
                            <div className={'follow-us'}>
                                <FormattedMessage id='follow_us' />
                            </div>
                            <a href='http://facebook.com/faworiter' target='_blank'>
                                <img className={'social-networks-img'} src={iconFB} alt='facbook' />
                            </a>
                            <a href='https://www.instagram.com/faworiter' target='_blank'>
                                <img className={'social-networks-img'} src={iconINST} alt='instagram' />
                            </a>
                        </div>
                    </div>
                </div> */}
                <div className={'copyright'}>	
                    All rights reserved. &#169; 2021 Con Lingua AB and Miber AB
                </div>
            </div>
        </footer>
    );
}

export default Footer;