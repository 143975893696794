const getCookie = (name) => {
    let value = '; ' + document.cookie;
    let parts = value.split('; ' + name + '=');
    if (parts.length === 2)
        return parts
            .pop()
            .split(';')
            .shift();
}

const setCookie = (cname, cvalue, exMins) => {
    const d = new Date();
    d.setTime(d.getTime() + (exMins*60*1000));
    const expires = 'expires='+d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires;
}

export {
    getCookie,
    setCookie
}